<template>
<sub-page
    v-model="$store.state.settings.pageData"
>
  <!-- Year Selector -->
  <v-sheet class="pa-4 mt-5 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
    <div class="d-flex align-center">
      <ft-select
          v-model="selectedYear"
          :items="yearsSelect" >
        <h4 class="mr-3">
          {{ $t('Year_') }} : {{ selectedYear }}
          <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
        </h4>
      </ft-select>

      <!--    <ft-select-->
      <!--        v-model="selectedMonth"-->
      <!--        :items="monthsSelect" >-->
      <!--      <h4>-->
      <!--        {{ $t('Months') }} : {{ $t(`Month_${selectedMonth}`)  }}-->
      <!--        <v-icon :color="wsACCENT">mdi-menu-down</v-icon>-->
      <!--      </h4>-->
      <!--    </ft-select>-->
    </div>
  </v-sheet>

  <!-- General Numbers -->
  <v-row class="mt-6">
    <v-col cols="4">
      <v-sheet :style="`border : 1px solid ${wsACCENT}`" class="wsRoundedHalf px-8 py-4  d-flex ">
        <v-icon :color="wsACCENT" class="mr-4" x-large>mdi-cash-multiple</v-icon>
        <div>
          <h2 class="wsACCENT">{{  yearIncomeValue }} USD</h2>
          <h4 class="wsACCENT font-weight-regular">Надходження</h4>
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="4">
      <v-sheet :style="`border : 1px solid ${wsACCENT}`" class="wsRoundedHalf px-8 py-4  d-flex ">
        <v-icon :color="wsACCENT" class="mr-4" x-large>mdi-abacus</v-icon>
        <div>
          <h2 class="wsACCENT">{{  yearExpensesValue }} USD</h2>
          <h4 class="wsACCENT font-weight-regular">Витрати</h4>
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="4">
      <v-sheet :color="yearProfitValue > 0 ? wsSUCCESS : wsWARNING" dark class="wsRoundedHalf px-8 py-4  d-flex ">
        <v-icon class="mr-4" x-large>mdi-currency-usd</v-icon>
        <div>
          <h2>{{  yearProfitValue }} USD</h2>
          <h4 class="font-weight-regular">{{ yearProfitValue > 0 ? 'Прибуток' : 'Збиток' }}</h4>
        </div>

      </v-sheet>
    </v-col>
  </v-row>
  <!-- Income Diagrams -->
  <v-row no-gutters>
    <v-col cols="8">
      <apexchart type="bar" height="300" :options="chartOptions" :series="series"></apexchart>
    </v-col>
    <v-col cols="4">
      <apexchart type="bar" height="300" :options="chartProfitOptions" :series="profitSeries"></apexchart>
    </v-col>
  </v-row>
  <!-- Subscription Diagrams -->
  <v-row>
    <v-col cols="8">
      <apexchart type="line" :height="300" :options="chartSubscriptionTotalOptions" :series="subscriptionTotalSeries"></apexchart>
    </v-col>
    <v-col cols="4">

      <div class="d-flex flex-column justify-start py-4">
        <!-- Income Pie -->
        <apexchart
            v-if="incomePieSeries.length > 0"
            type="pie" height="150"  :options="pieIncomeOptions" :series="incomePieSeries"></apexchart>
        <v-sheet v-else class="py-8 px-6 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
          <div class="d-flex justify-center">
            <v-icon large :color="wsDARKLIGHT">mdi-currency-usd-off</v-icon>
          </div>
          <h3 class="wsACCENT text-center mx-auto mt-3" style="max-width: 300px">На даний момент надходжень не має</h3>
        </v-sheet>

        <!-- Expense Pie -->
        <apexchart
            v-if="expensePieSeries.length > 0"
            type="pie" height="150" :options="pieExpenseOptions" :series="expensePieSeries"></apexchart>
        <v-sheet v-else class="py-8 px-6 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
          <div class="d-flex justify-center">
            <v-icon large :color="wsDARKLIGHT">mdi-currency-usd-off</v-icon>
          </div>
          <h3 class="wsACCENT text-center mx-auto mt-3" style="max-width: 300px">На даний момент витрат не має</h3>
        </v-sheet>

      </div>

    </v-col>
  </v-row>



  <!-- Clients Graphics -->
  <v-row>
    <v-col cols="8">
      <apexchart type="line" :height="300" :options="chartActiveOptions" :series="activeSeries"></apexchart>
    </v-col>
    <v-col cols="4">
      <apexchart type="candlestick" height="350" :options="candleOptions" :series="candleSeries"></apexchart>
    </v-col>
  </v-row>

  <apexchart type="bar" :height="generalHeight*1.5" :options="chartRegistrationsOptions" :series="registrationsSeries"></apexchart>

</sub-page>
</template>

<script>
import apexchart from "vue-apexcharts";
import {mapActions} from "vuex";
export default {
  name: "accounitngPayments",
  components : {
    apexchart
  },
  data() {
    return {
      selectedYear : "2024",
      selectedMonth : 1,
      date : null,
      years : [],
      months : [],

      yearProfitValue : 0,
      yearIncomeValue : 0,
      yearExpensesValue : 0,
      clientsMin : 0,
      clientsMax : 0,
      subscriptionMin : 0,
      subscriptionMax : 0,

      series: [
        {
          name: 'Дохід',
          data: []
        },
        {
          name: 'Видакти',
          data: []
        }
      ],
      profitSeries : [
        {
          name : "Прибуток"
        }
      ],
      registrationsSeries : [
        {
          name : "Реєстрації"
        },
        {
          name : "Конверсії"
        }
      ],
      activeSeries : [
        {
          name : "Активні студенти"
        },
      ],
      subscriptionTotalSeries : [
        {
          name : "Місячна підписка"
        },
      ],
      candleSeries: [{
        data: []
      }],
      incomePieSeries :  [44, 55, 13, 43, 22],
      expensePieSeries :  [44, 55, 13, 43, 22],
      incomePieLabels :  [],
      expensePieLabels :  [],
    }
  },
  computed : {
    generalHeight() {
      return 120 + ((this.monthsSelect.length - 1) *40)
    },
    pieIncomeOptions() {
      return {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: this.incomePieLabels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    pieExpenseOptions() {
      return {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: this.expensePieLabels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    candleOptions() {
      return {
        chart: {
          type: 'candlestick',
          height: 350,
        },


        plotOptions : {
          candlestick: {
            colors: {
              upward: '#7AB971',
              downward: '#FE4B4B'
            },
            wick: {
              useFillColor: true,
            }
          }
        },

        title: {
          text: 'Динаміка приросту клієнтів',
          align: 'left'
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        }
      }
    },
    chartActiveOptions() {
      return {
        chart: {
          height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },

        colors: ['#567186', '#283E4F'],
          dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Активні клієнти протягом мясяця',
              align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
              row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: this.monthsForGeneralChart,
              title: {
            text: 'Місяці'
          }
        },
        yaxis: {
          title: {
            text: 'Клієнти'
          },
          min: this.clientsMin,
          max: this.clientsMax
        },
        legend: {
          position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
        }
      }
    },
    chartSubscriptionTotalOptions() {
      return {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },

      colors: ['#567186', '#283E4F'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Місячна підписка відповідно кількості клієнтів',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: this.monthsForGeneralChart,
        title: {
          text: 'Місяці'
        }
      },
      yaxis: {
        title: {
          text: 'USD'
        },
        min: this.subscriptionMin,
        max: this.subscriptionMax
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }
    },
    chartRegistrationsOptions() {
      return  {
      chart: {
        type: 'bar',
        height: 300,
      },
      colors: ['#567186', '#ED8A58' , '#7AB971', '#FE4B4B'],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: this.monthsForGeneralChart,
      },
    } },
    chartOptions() {
      return  {
      chart: {
        type: 'bar',
        height: 300,
      },
      colors: ['#567186', '#ED8A58'],
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '100%',
          dataLabels: {
            position: 'center',
          },
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          colors: ['#94b3c7' , '#e7a589']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: '#fff'
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: this.monthsForGeneralChart,
      },
    } },
    chartProfitOptions() { return  {
      chart: {
        type: 'bar',
        height: 300,
        stacked: true
      },
      colors: ['#7AB971', '#fe4b4b'],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        style: {
          fontSize: '10px',
          colors: ['#94b3c7' , '#e7a589']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: '#fff'
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: this.monthsForGeneralProfitChart,
        show: true,
      },
    } },
    totalPerMonth() {
      let sum = 0
      let items = this.items.map(el => parseInt(el.plan_price))
      items.forEach(el => {
        sum += el
      })
      return sum
    },
    yearsSelect() {
      let items = []
      this.years.forEach(el => {
        items.push( { text : el , value : el })
      })

      return items
    },
    monthsForGeneralChart() {
      if ( this.months.length === 0 ) {
        return []
      }
      let items = []
      let filteredMonths = this.months.filter(el => el.year === this.selectedYear)
      filteredMonths.forEach( item => {
        items.push(this.$t(`Month_${item.month}`))
      })
      return items

    },
    monthsForGeneralProfitChart() {
      if ( this.months.length === 0 ) {
        return []
      }
      let items = []
      let filteredMonths = this.months.filter(el => el.year === this.selectedYear)
      filteredMonths.forEach( () => {
        items.push('')
      })
      return items
    },

    monthsSelect() {
      if ( this.months.length === 0 ) {
        return []
      }
      let items = []
      let filteredMonths = this.months.filter(el => el.year === this.selectedYear)
      filteredMonths.forEach( item => {
        items.push({
          text : this.$t(`Month_${item.month}`) , value :item.month
        })
      })
      return items
    },
  },
  watch : {
    selectedYear() {
      this.initPage()
    },
    selectedMonth() {
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_STATISTICS',
    ]),

    getPlanName(price) {
      switch (price) {
        case  11 : return 'Базовий '
        case  37 : return 'Елітний '
        case  79 : return 'Елітний+'
        default: return `Інший`
      }
    },
    initGeneralChart(data) {
      let income = []
      let expense = []
      let profit = []
      let loss = []
      let registrations = []
      let conversions = []
      let activeBusinesses = []
      let candleData = []
      let bounced = []
      let increase = []
      let subscriptionTotalSeries = []


      this.yearProfitValue  = 0
      this.yearIncomeValue  = 0
      this.yearExpensesValue  = 0
      this.clientsMin = 99999999999999
      this.clientsMax = 0
      this.subscriptionMin = 99999999999999
      this.subscriptionMax = 0





      this.monthsSelect.forEach((el,index) => {
        this.yearIncomeValue += parseInt(data.income[el.value] || 0)
        this.yearExpensesValue += parseInt(data.expense[el.value] || 0)
        income.push(data.income[el.value] || 0)
        expense.push(data.expense[el.value] || 0)
        subscriptionTotalSeries.push(data.monthly_subscription_total[el.value] || 0 )

        registrations.push(data.registrations[el.value] || 0)
        conversions.push(data.conversions[el.value] || 0)
        activeBusinesses.push(data.clients[el.value] || 0)

        let profitValue = (data.income[el.value] || 0) - (data.expense[el.value] || 0)
        profit.push( profitValue > 0 ? profitValue : 0  )
        loss.push( profitValue > 0 ? 0 : profitValue  )

        this.clientsMax = (data.clients[el.value] || 0) > this.clientsMax ? data.clients[el.value] : this.clientsMax
        this.clientsMin = (data.clients[el.value] || 0) < this.clientsMin ? data.clients[el.value] : this.clientsMin

        this.subscriptionMax = (data.monthly_subscription_total[el.value] || 0) > this.subscriptionMax ? data.monthly_subscription_total[el.value] : this.subscriptionMax
        this.subscriptionMin = (data.monthly_subscription_total[el.value] || 0) < this.subscriptionMin ? data.monthly_subscription_total[el.value] : this.subscriptionMin


        let bouncedValue = 0
        let increaseValue = 0
        let minimumClients = data.clients[el.value]
        let maximumClients = data.clients[el.value]
        if ( index > 0) {
          minimumClients = data.clients[parseInt(el.value) - 1]
          maximumClients = data.clients[parseInt(el.value) - 1] + parseInt(data.conversions[el.value] || 0)

          bouncedValue = maximumClients -  data.clients[parseInt(el.value)]
          if ( bouncedValue < 0 ) {
            bouncedValue = 0
          }
          increaseValue = (data.conversions[el.value] || 0) - bouncedValue

        }
        bounced.push(bouncedValue)
        increase.push(increaseValue)
        candleData.push({
          x : el.value,
          y : [ minimumClients , maximumClients , minimumClients ,data.clients[el.value] ]
        })

      })

      this.clientsMax += parseInt(this.clientsMax*0.1)
      this.clientsMin -= parseInt(this.clientsMin*0.1)

      this.subscriptionMax += parseInt(this.subscriptionMax*0.1)
      this.subscriptionMin -= parseInt(this.subscriptionMin*0.1)

      this.yearProfitValue  = this.yearIncomeValue - this.yearExpensesValue

      this.series = [
        {
          name: 'Дохід',
          data: income
        },
        {
          name: 'Витрати',
          data: expense
        }
      ]
      this.profitSeries = [
        {
          name: 'Прибуток',
          data: profit
        },
        {
          name: 'Збиток',
          data: loss
        },
      ]
      this.activeSeries = [
        {
          name : "Активні студенти",
          data : activeBusinesses
        }
      ]
      this.subscriptionTotalSeries =[
        {
          name : "Місячна підписка",
          data : subscriptionTotalSeries
        }
      ]

      this.candleSeries = [{
        data: candleData
      }],

      this.registrationsSeries = [
        {
          name : "Реєстрації",
          data: registrations
        },
        {
          name : "Конверсії",
          data: conversions
        },
        {
          name : "Приріст",
          data: increase
        },
        {
          name : "Втрати",
          data: bounced
        }
      ]


      this.expensePieSeries = []
      this.expensePieLabels = []
      this.incomePieSeries = []
      this.incomePieLabels = []

      data.income_pie.forEach(el => {
        this.incomePieLabels.push( this.getPlanName(parseInt(el.plan)))
        this.incomePieSeries.push(parseInt(el.total))
      })

      data.expense_pie.forEach(el => {
        this.expensePieLabels.push(el.name)
        this.expensePieSeries.push(parseInt(el.total))
      })

    },
    initDates() {
      // Get the current date
      const currentDate = new Date();

      const startDate = new Date(2022, 6); // Note: Months are 0-indexed, so 6 represents July.
      const monthsAndYears = [];
      const uniqueYears = [];

      while (startDate <= currentDate) {
        const month = startDate.getMonth() + 1; // Adding 1 to convert to 1-based index
        const year = startDate.getFullYear();

        // Add the month and year to the monthsAndYears array
        monthsAndYears.push({ month: month.toString(), year: year.toString() });

        // Add the year to the uniqueYears array if it's not already there
        if (!uniqueYears.includes(year.toString())) {
          uniqueYears.push(year.toString());
        }

        // Move to the next month
        startDate.setMonth(startDate.getMonth() + 1);

        this.months = monthsAndYears
        this.years = uniqueYears
      }

      this.selectedYear = uniqueYears[uniqueYears.length - 1]
      this.selectedMonth = monthsAndYears[monthsAndYears.length - 1].month

    },
    async initPage() {
      let result = await this.GET_STATISTICS(this.selectedYear)
      if ( !result ) {
        return this.ERROR()
      }
      this.initGeneralChart(result)
    }
  },
  mounted() {
    this.initDates()
    this.initPage()
  }
}
</script>

<style scoped>

</style>